<template>
  <div id="app" class="site grid-container container hfeed" style="height: auto !important">
    <HeadNav selectedLanguage="en"/>

    <div id="content" class="site-content" style="height: auto !important">
      <article id="article" class="center" style="height: auto !important">
        <section id="downloadpage" style="height: auto !important">
          <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
          <h1>GB WhatsApp APK Download with Latest Version 2025</h1>
          <p>
            The earliest WhatsApp Mods <a href="https://www.gbwhatsapp.chat/" class="jump-url">GBWhatsApp</a> is a non-official WhatsApp version that allows you to hide double blue checks or last connection time and many more features. It also provides tons of features including plenty of GB Themes, ghost mode, changing font styles, app locks, conversation locks, privacy options and others.
          </p>
          <figure class="wp-block-table">
            <table>
              <tr>
                <td>App Name</td>
                <td>GB WhatsApp</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>77 MB</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>v18.30</td>
              </tr>
              <tr>
                <td>OS</td>
                <td>Android</td>
              </tr>
              <tr>
                <td>Last Update</td>
                <td>12 hours ago</td>
              </tr>
              <tr>
                <td>License</td>
                <td>Free</td>
              </tr>
              <tr>
                <td>Enhanced</td>
                <td>Anti Ban</td>
              </tr>
            </table>
          </figure>

          <div id="download" class="wp-block-buttons aligncenter"
            style="width: 100%; display: block; text-align: center" @click="downloadfrom('bigbtn')">
            <div class="wp-block-button">
              <div class="godxthemes-btn wp-block-button__linkvk ripple">
                <i class="material-icons">
                  <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" fill="#fff">
                    <path
                      d="m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z" />
                  </svg>
                </i>
                GBWhatsApp Download
              </div>
            </div>
          </div>
          <div class="wpx5">
            <p class="has-text-align-center" style="height: auto !important">
              If the download doesn't start in a few seconds,
              <span id="downloadapk" @click="download()">
                click here
              </span>
            </p>
          </div>
          <center>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button">
                <a href="https://www.gbwhatsapp.chat/" class="wp-block-button__linkvk clickable">
                  <i class="material-icons">
                    <svg class="icon" fill="#fff" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" width="24px" height="24px">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M14.91 6.71c-.39-.39-1.02-.39-1.41 0L8.91 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L11.03 12l3.88-3.88c.38-.39.38-1.03 0-1.41z" />
                    </svg>
                  </i>
                  Go to GB Blogs
                </a>
              </div>
            </div>
          </center>

          <a id="abc" :href="apk" target="_self" download />
          <div class="wpxads">
            <center>
              <br>
              <div class="wp-block-buttons is-content-justification-center">
                <div class="wp-block-button">
                  <a href="https://t.me/gb_download" target="_blank"
                    class="dlbtn wp-block-button__linkvk clickable">
                    Join On Telegram
                  </a>
                </div>
              </div>
              <br>
              <p class="btns">
                <a href="https://gbwhatpro.com/yowhatsapp-apk-download-n">YOWhatsApp</a>
                <a href="https://gbwhatapks.com/fm-whatsapp-new/">FMWhatsApp</a>
                <a href="https://gbapks.com.pk/">WhatsApp Plus</a>
              </p>
            </center>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/download.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Download',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'download',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  mounted () {
    document.documentElement.lang = 'en';
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom (reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    getAPK () {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk (type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download () {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>

<style lang="scss" scoped>
.wp-block-table {
  width: 70%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .wp-block-table {
    width: 100%;
  }
}
</style>